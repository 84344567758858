@font-face {
  font-family: Aileron-SemiBold;
  src: url("Aileron-SemiBold.ttf.9403fd63.woff") format("woff"), url("Aileron-SemiBold.ttf.78aed9f9.svg#Aileron-SemiBold") format("svg"), url("Aileron-SemiBold.ttf.dd9671cd.eot"), url("Aileron-SemiBold.ttf.dd9671cd.eot#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

:root {
  --bar-width: 35px;
  --bar-height: 3px;
  --hamburger-gap: 6px;
  --hamburger-menu-background: #6ea3de;
  --hamburger-margin: 6px;
  --animation-timing: .2s ease-in-out;
  --animation-timing-sidebar: .4s ease-in-out;
  --hamburger-height: calc(var(--bar-height) * 3 + var(--hamburger-gap) * 2);
  --hamburger-font-color: #083973;
  --sidebar-background-color: #fff3;
  --sticky-nav-bg-color: #fffffff2;
  --foreground: #333;
  --background: white;
  --main-font: "Aileron SemiBold", "Aileron-SemiBold", "Manrope", sans-serif;
  --main-title-color: #011ed4;
  --nav-font-color: #68a0dd;
  --card-text-font-size: 16px;
  --invalid-color: red;
}

* {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  font-family: Arial, Helvetica, sans-serif;
}

.hamburger-menu:before, .hamburger-menu:after, .hamburger-menu input {
  content: "";
  width: var(--bar-width);
  height: var(--bar-height);
  background-color: var(--foreground);
  transform-origin: 0;
  transition: opacity var(--animation-timing), width var(--animation-timing), rotate var(--animation-timing), translate var(--animation-timing);
}

.hamburger-menu {
  --x-width: calc(var(--hamburger-height) * 1.41421);
  right: var(--hamburger-margin);
  top: var(--hamburger-margin);
  z-index: 1000;
  gap: var(--hamburger-gap);
  background-color: var(--hamburger-menu-background);
  cursor: pointer;
  border-radius: 6px;
  flex-direction: column;
  align-items: center;
  width: 3rem;
  height: 3rem;
  padding: 13px 0 10px;
  display: flex;
  position: absolute;
}

.hamburger-menu input {
  -webkit-appearance: none;
  appearance: none;
  pointer-events: none;
  outline: none;
}

.hamburger-menu:has(input:checked):before {
  width: var(--x-width);
  rotate: 45deg;
  translate: 5px calc(var(--bar-height) / -2);
}

.hamburger-menu:has(input:checked):after {
  width: var(--x-width);
  rotate: -45deg;
  translate: 5px calc(var(--bar-height) / 2);
}

.hamburger-menu input:checked {
  opacity: 0;
  width: 0;
}

.hamburger-nav ul {
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-top: 5rem;
  list-style: none;
  display: flex;
}

.hamburger-nav li {
  height: 10px;
}

.hamburger-nav a {
  color: #000;
  align-items: center;
  padding: 0 30px;
  text-decoration: none;
  display: flex;
}

.hamburger-nav a:hover {
  background-color: #f0f0f0;
}

.sidebar {
  z-index: 800;
  background-color: var(--sidebar-background-color);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  text-align: center;
  transition: translate var(--animation-timing-sidebar);
  flex-direction: column;
  justify-content: flex-start;
  width: 100vw;
  height: 100vh;
  margin-top: 0;
  padding-left: 0;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  translate: 100%;
}

.nav__links {
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.nav__links ul li {
  text-align: center;
  width: 100%;
}

.language__link {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
}

.language__link a {
  color: var(--hamburger-font-color);
  width: 100%;
  font-size: 30px;
}

.hamburger-menu:has(input:checked) + .sidebar {
  translate: 0;
}

.sidebar li a {
  color: var(--hamburger-font-color);
  width: auto;
  font-size: 30px;
}

.sidebar li {
  padding-bottom: 4rem;
}

.sidebar a {
  width: 100%;
}

.sticky {
  background-color: var(--sticky-nav-bg-color);
  position: fixed;
}

header {
  font-family: var(--main-font);
  width: 100vw;
  height: 60px;
}

.flags, .large-nav {
  display: none;
}

.logo-box {
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 5px;
}

.hero-box {
  background: #000 url("pexels-anthony-derosa-216216_compressed.445d4d38.jpg") center / cover;
  width: 100vw;
  height: 70vh;
  overflow: hidden;
}

.image-overlay {
  background-color: #0000004d;
  width: 100vw;
  height: 70vh;
}

.motto-box {
  color: #fff;
  font-family: var(--main-font);
  z-index: 200;
  opacity: .99;
  justify-content: center;
  align-items: center;
  transition: opacity 1s ease-in, visibility 0s ease-in 2ms;
  display: flex;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: opacity 1s ease-in, visibility 0s ease-in .3s;
}

.motto {
  text-align: center;
  opacity: .99;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
}

#photo-credit {
  padding-left: 4px;
  font-size: 10px;
}

h1 {
  font-family: var(--main-font);
  text-align: center;
  color: var(--main-title-color);
  font-weight: bold;
}

.sections {
  margin: 30rem, 0rem, 20rem, 0rem;
  padding-top: 3rem;
  scroll-margin-top: 8rem;
  transition: transform 1s, opacity 1s;
}

.sections h2 {
  font-family: (--main-font);
  color: var(--main-title-color);
  padding-left: 1rem;
  font-size: 25px;
  font-weight: 500;
}

.section-card {
  background-color: #f1f1f1;
  margin-bottom: 2rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.section-card h3 {
  padding-top: .5rem;
  padding-left: 1rem;
}

.section-card p {
  font-size: var(--card-text-font-size);
  padding-left: 2rem;
  padding-right: 2rem;
  line-height: 25px;
}

.picture-container {
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  display: flex;
}

.example-pic {
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin-bottom: 1rem;
  margin-left: auto;
}

.example-pic img {
  max-width: 100%;
  margin-left: auto;
}

.profile-img {
  border-radius: 50%;
}

.image-container {
  text-align: center;
  z-index: 100;
}

#todd-lowrey .section-card {
  z-index: -10;
  margin-top: -5rem;
  padding-top: 5rem;
}

footer {
  justify-content: center;
  align-items: center;
  display: flex;
}

.section-hidden {
  opacity: 0;
  transform: translateY(8rem);
}

.japanese {
  line-height: 1.5rem;
}

#contact-form {
  padding: 3rem 2rem 2rem;
}

.form-description-container {
  text-align: justify;
}

.form-description {
  line-height: 25px;
}

#submit_button {
  background-color: var(--main-title-color);
  color: #fff;
  border: none;
  border-radius: 10px;
  margin-left: 10px;
  padding: .5rem 1.5rem;
  font-size: 18px;
}

.row {
  margin-top: .5rem;
}

.row label {
  margin-left: 10px;
  font-size: 18px;
}

input[type="text"], #subject, textarea {
  text-align: left;
  background-color: var(--hamburger-menu-background);
  color: #000;
  border: none;
  width: 80%;
  margin: 10px 0 5px 10px;
}

input[type="text"], #subject {
  height: 25px;
}

#subject {
  color: #000;
  font-weight: 500;
}

textarea {
  text-align: left;
  padding: 5px;
}

input:focus, textarea:focus {
  outline-color: var(--main-title-color);
}

.required-field {
  color: var(--invalid-color);
  padding-left: .5rem;
  font-size: .8rem;
}

.error_message {
  color: var(--invalid-color);
  margin-bottom: 10px;
  margin-left: 10px;
  font-size: .8rem;
}

#submit_button, input, textarea, select {
  cursor: pointer;
}

.button-box, .japanese-button-box {
  justify-content: right;
  width: 80%;
  display: flex;
}

#japanese_submit_button {
  background-color: var(--main-title-color);
  color: #fff;
  border: none;
  border-radius: 10px;
  margin-left: 10px;
  padding: .5rem 2rem;
  font-size: 18px;
}

hr {
  color: #000;
  background-color: #000;
  border: none;
  width: 80%;
  height: 2px;
  margin-top: 4rem;
}

footer {
  background-color: var(--nav-font-color);
  height: 5rem;
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
  .sidebar {
    z-index: 400;
    background-color: var(--sidebar-background-color);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 30vw;
    min-height: 100vh;
    margin-top: 0;
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
  }

  .sidebar li a {
    font-size: 20px;
  }

  .language__link a {
    font-size: 20px;
    font-weight: 600;
  }

  .language__link {
    padding-bottom: 4rem;
  }

  .sidebar li {
    padding-bottom: 5rem;
  }

  input[type="text"], textarea {
    width: 50%;
  }

  #subject {
    width: 30%;
  }

  .button-box, .japanese-button-box {
    width: 50%;
  }
}

@media only screen and (min-width: 768px) {
  .sidebar {
    z-index: 400;
    background-color: var(--sidebar-background-color);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 30vw;
    min-height: 100vh;
    margin-top: 0;
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
  }

  .sidebar li a {
    font-size: 20px;
  }

  .language__link a {
    font-size: 20px;
    font-weight: 600;
  }

  .sidebar li {
    padding-bottom: 5rem;
  }

  .language__link {
    padding-bottom: 4rem;
  }

  .motto-box {
    top: 30%;
    left: 50%;
  }

  .motto {
    opacity: .99;
    margin-left: 0;
    font-size: 40px;
    font-weight: 900;
  }

  h2 {
    margin-left: 1.5rem;
  }

  .flex-container {
    justify-content: space-around;
    margin-bottom: 2rem;
    display: flex;
  }

  .flex-container .section-card {
    width: 40%;
  }

  #our-business .section-card {
    width: 80%;
    margin: auto auto 3rem;
  }

  #english-proofreading {
    justify-items: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  #english-proofreading .section-card {
    width: 80%;
    margin: auto auto 3rem;
  }

  .picture-container {
    flex-direction: row;
    justify-content: space-evenly;
    width: 80vw;
    margin: 2rem 0;
    padding: 0 1rem;
    display: flex;
  }

  .example-pic {
    max-width: 80%;
    margin-bottom: 0;
  }

  .example-pic img {
    object-fit: contain;
  }

  #todd-lowrey {
    justify-items: center;
  }

  #todd-lowrey .section-card {
    z-index: -10;
    width: 80%;
    margin: -4rem auto 3rem;
    padding-top: 3rem;
  }

  .form-card {
    justify-content: center;
    display: flex;
  }

  .form-description {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }

  input[type="text"], textarea, .button-box, .japanese-button-box {
    width: 100%;
  }
}

@media only screen and (min-width: 1025px) {
  .hamburger-nav {
    display: none;
  }

  header {
    flex-direction: row;
    width: 100%;
    display: flex;
  }

  .flags {
    margin-right: 1rem;
    padding-top: 10px;
    display: block;
  }

  .large-nav {
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .nav__links {
    flex-direction: row;
    margin-top: 0;
  }

  .nav__links a:hover {
    color: var(--hamburger-font-color);
  }

  nav ul {
    margin-left: auto;
    padding: 0;
    list-style-type: none;
    display: flex;
  }

  nav a {
    color: var(--nav-font-color);
    font-family: var(--main-font);
    text-decoration: none;
  }

  nav li {
    margin: 0 2rem;
    font-size: 15px;
  }

  .blog__box a {
    font-size: 15px;
  }

  .blog__box {
    margin-left: auto;
    margin-right: auto;
  }

  .language-selector {
    flex-direction: row;
    margin-left: auto;
    margin-right: 1rem;
    display: flex;
  }

  #jpn-flag img {
    width: 35px;
    height: 25px;
    margin-right: 20px;
    box-shadow: 5px 5px 5px #ccc;
  }

  #can-flag img {
    width: 35px;
    height: 25px;
    box-shadow: 5px 5px 5px #ccc;
  }

  .motto-box {
    top: 30%;
    left: 50%;
  }

  .motto {
    margin-left: 0;
    font-size: 40px;
    font-weight: 900;
  }

  .sections {
    scroll-margin-top: 6rem;
  }

  .main-divider {
    width: 70%;
  }

  .form-description {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }

  #our-services, #our-business {
    width: 80%;
    margin: 0 auto;
  }

  #english-proofreading {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .picture-container {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    display: flex;
  }

  .example-pic {
    max-width: 80%;
    margin-bottom: 0;
  }

  .example-pic img {
    object-fit: contain;
  }

  #todd-lowrey {
    width: 80%;
    margin: 0 auto;
  }

  #contact-form {
    width: 80%;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1281px) {
  .hamburger-nav {
    display: none;
  }

  .large-nav {
    display: flex;
  }

  nav li {
    margin: 0 3rem;
    font-size: 18px;
  }

  .blog__box a {
    font-size: 18px;
  }

  .blog__box {
    width: -moz-fit-content;
    width: fit-content;
    margin-left: 5rem;
    margin-right: 4rem;
  }

  .form-description-container {
    text-align: center;
  }
}

/*# sourceMappingURL=index.1daa0ea8.css.map */
